<template>
    <div>
        <el-form ref="dataForm" :model="dataForm" inline label-width="110px" :inline="true" class="border-form">
            <el-card>
                <el-row class="set_border">
                    <el-col :span="11" class="col-set_border">
                        <el-form-item label="工程名称" class="item">
                            <el-input class="sp-input1" type="input" v-model="dataForm.projectName"
                                placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9" class="col-set_border">
                        <el-form-item label="工作控制号" class="item">
                            <el-input class="sp-input" v-model="dataForm.workNo" placeholder="" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="shift-item">Shift No. </div>
                    </el-col>
                </el-row>
                <el-row class="set_border">
                    <el-col :span="11" class="col-set_border">
                        <el-form-item label="日期" class="item">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="dataForm.logDate" type="date"
                                style="width:400px">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9" class="col-set_border">
                        <el-form-item label="工程地点" class="item">
                            <el-input class="sp-input" v-model="dataForm.projectPlace" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-input class="shift-input" type="input" v-model="dataForm.logNo" placeholder=""></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11" class="col-set_border1">
                        <el-form-item label="天气" class="item">
                            <el-input class="sp-input1" v-model="dataForm.weather" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9" class="col-set_border2">
                        <el-form-item label="温度、湿度" class="item">
                            <el-col style="margin-left: 40px;">
                                <el-col :span="6">
                                    <el-input class="sp-input2" v-model="dataForm.temperature" placeholder=""
                                        style="margin-left: 50px;"></el-input>
                                </el-col>
                                <el-col :span="4" style="margin-left: 70px;margin-right: 10px;">
                                    ℃
                                </el-col>
                                <el-col :span="7">
                                    <el-input class="sp-input2" v-model="dataForm.humidity" placeholder=""
                                        style="margin-left: -30px;"></el-input>%
                                </el-col>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11" class="col-set_border1">
                        <el-form-item label="监理组长" class="item">
                            <el-input class="sp-input1" v-model="dataForm.leader" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9" class="col-set_border2">
                        <el-form-item label="监理工程师" class="item">
                            <el-input class="sp-input" v-model="dataForm.engineer" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button v-preventReClick  class="btn" type="primary" size="small"
                            @click="dataFormSubmit()" style="margin-left: 50px;">保存</el-button>
                        <el-button v-preventReClick class="rebtn" size="small" style="margin-top: 2px;"
                            @click="backPage()">返回</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <el-row style="margin-top: 20px;">
                <el-col :span="24">
                    <el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick">
                        <el-tab-pane :label="item.label" :key="item.name" v-for="item in tabs"
                            :name="item.name"></el-tab-pane>
                    </el-tabs>
                    <div style="margin-top: -16px;">
                        <el-card v-show="activeIndex === 'jobSummary'" class="set_card">
                            <el-col :span="24" style="margin-top: -10px;">
                                <el-form-item label="1.概述" class="tabItem">
                                </el-form-item>
                                <div v-for="(summary, index1) in dataForm.summaryList" :key="index1" class="">
                                    <el-row style="border-bottom:1px solid #dadada">
                                        <el-input class="note_input1" type="textarea" autosize
                                        v-model="dataForm.summaryList[index1].itemContent"></el-input>
                                        <el-button v-preventReClick type="text" size="small" class="prob_btn" style="margin-left: -50px;margin-top:0px"
                                            @click="deleteSummary(index1)">× 删除</el-button>
                                    </el-row>
                                </div>
                            </el-col>
                            <el-col :span="24" style="margin-bottom: 20px;">
                                <el-button v-preventReClick type="text" size="small"
                                    style="background-color: rgb(3, 73, 138);color: aliceblue;width: 90px;margin-top: 10px;"
                                    @click="addSummary()">+ 添加记录</el-button>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="2.报验情况" class="tabItem">
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <div v-for="(testRecord, index1) in dataForm.testRecordList" :key="index1" class="">
                                    <el-input class="test_input" v-model="dataForm.testRecordList[index1].itemContent"
                                        readOnly="true"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="3.巡检情况" class="tabItem" style="margin-top: 10px;">
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <div v-for="(inspection, index1) in dataForm.inspectionList" :key="index1" class="">
                                    <el-input class="test_input" v-model="dataForm.inspectionList[index1].itemContent"
                                        readOnly="true"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="4.会议情况" class="tabItem" style="margin-top: 10px;">
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <div v-for="(meet, index1) in dataForm.meetList" :key="index1" class="">
                                    <el-row style="border-bottom:1px solid #dadada">
                                        <el-input v-if="meet.itemSysFlg === '0'" class="note_input1" type="textarea" autosize
                                            v-model="dataForm.meetList[index1].itemContent" readOnly="true"></el-input>
                                        <el-input v-if="meet.itemSysFlg === '1'" class="note_input1" type="textarea" autosize
                                            v-model="dataForm.meetList[index1].itemContent"></el-input>
                                        <el-button v-if="meet.itemSysFlg === '1'" v-preventReClick type="text" size="small" style="margin-left: -50px;margin-top:0px"
                                            class="prob_btn" @click="deleteMeet(index1)">× 删除</el-button>
                                    </el-row>
                                </div>
                            </el-col>
                            <el-col :span="24" style="margin-bottom: 20px;">
                                <el-button v-preventReClick type="text" size="small"
                                    style="background-color: rgb(3, 73, 138);color: aliceblue;width: 90px;margin-top: 10px;"
                                    @click="addMeet()">+ 添加会议情况</el-button>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="5.协调记录" class="tabItem">
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <div v-for="(coordinateRecord, index1) in dataForm.coordinateRecordList" :key="index1"
                                    class="">
                                    <el-input class="test_input" v-model="dataForm.coordinateRecordList[index1].itemContent"
                                        readOnly="true"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="24" style="margin-top: 10px;">
                                <el-form-item label="6.其他" class="tabItem">
                                </el-form-item>
                                <div v-for="(other, index1) in dataForm.otherList" :key="index1" class="">
                                    <el-row style="border-bottom:1px solid #dadada">
                                        <el-input class="note_input1" type="textarea" autosize 
                                            v-model="dataForm.otherList[index1].itemContent"></el-input>
                                        <el-button v-preventReClick type="text" size="small" class="prob_btn" style="margin-left: -50px;margin-top:0px"
                                            @click="deleteOther(index1)">× 删除</el-button>
                                    </el-row>
                                </div>
                                <el-button v-preventReClick type="text" size="small"
                                    style="background-color: rgb(3, 73, 138);color: aliceblue;width: 90px;margin-top: 20px;margin-bottom: 10px;"
                                    @click="addOther()">+ 添加其他</el-button>
                            </el-col>
                        </el-card>
                        <el-card v-show="activeIndex === 'signDocuments'" class="set_card">
                            <div v-for="(note, index1) in dataForm.fileList" :key="index1" class="">
                                <el-input class="test_input" v-model="dataForm.fileList[index1].itemContent"
                                    readOnly="true"></el-input>
                            </div>
                        </el-card>
                        <el-card v-show="activeIndex === 'notes'" class="set_card">
                            <el-col :span="24" style="margin-bottom: 20px;">
                                <div v-for="(note, index1) in dataForm.noteList" :key="index1" class="">
                                    <el-row style="border-bottom:1px solid #dadada">
                                        <el-input class="note_input1" type="textarea" autosize v-model="dataForm.noteList[index1].itemContent"></el-input>
                                        <el-button v-preventReClick type="text" size="small" class="prob_btn" style="margin-left: -50px;margin-top:0px"
                                            @click="deleteNote(index1)">× 删除</el-button>
                                    </el-row>
                                </div>
                                <el-button v-preventReClick type="text" size="small"
                                    style="background-color: rgb(3, 73, 138);color: aliceblue;width: 90px;margin-top: 20px;"
                                    @click="addNote()">+ 添加记录</el-button>
                            </el-col>
                        </el-card>
                        <el-card v-show="activeIndex === 'atachedComments'" class="set_card">
                            <el-col :span="4">
                                <el-button v-preventReClick type="text" size="small"
                                    style="background-color: rgb(3, 73, 138);color: aliceblue;width: 110px;margin-top: 10px; margin-bottom: 20px;"
                                    @click="selectPhoto()">+ 添加照片</el-button>
                            </el-col>
                            <el-col :span="12" style="margin-top: 20px;">
                                <span style="color: coral;">默认把当天的照片都提取出来，包括质量和进度</span>
                            </el-col>
                            <el-col :span="24">
                                <el-row>
                                    <div class="card" v-for="(photo, index) in dataForm.photoList" :key="index"
                                        :style="{ order: photo.sort }" @dragstart="dragStart(index)" @dragover.prevent
                                        @drop="drop(index)" @dragend="dragEnd" draggable="true">
                                        <el-col :span="4">
                                            <el-card :body-style="{ padding: '0px' }"
                                                style="margin-bottom: 10px;margin-right: 15px;height: 285px;">
                                                <el-image :src="photo.photoFileUri" fit="scale-down"
                                                    class="image"></el-image>
                                                <div style="padding: 2px 10px;">
                                                    <div class="t-column-ellipsis">
                                                        <span>{{ photo.photoContent }}</span>
                                                    </div>
                                                    <div class="bottom clearfix">
                                                        <el-button type="text" class="button"
                                                            @click="delImg(index)">删除</el-button>
                                                        <el-button type="text" class="button" style="margin-right: 10px;"
                                                            @click="updateImgContent(index, photo.photoContent)">修改</el-button>
                                                    </div>
                                                </div>
                                            </el-card>
                                        </el-col>
                                    </div>
                                </el-row>
                            </el-col>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <!-- 质量进度照片内容修改 -->
        <el-dialog title="修改描述" :visible.sync="updateImgContentVisable" width="30%" :close-on-click-modal="false">
            <el-form>
                <el-row>
                    <el-form-item label="">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" v-model="updatePhotoContent"
                            placeholder="请填写" style="width:400px"></el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="saveImgContent">确 定</el-button>
                <el-button v-preventReClick class="rebtn" size="small" @click="updateImgContentVisable = false">取 消</el-button>
            </span>
        </el-dialog>
        <photo-select v-if="photoSelectVisible" ref="photoSelect" @selectItems="margeSelectItems"></photo-select>
    </div>
</template>

<script>
import $common from "@/utils/common.js"
import PhotoSelect from './workloginfo-photo-select.vue'
export default {
    name: "workloginfo-preset",
    data() {
        return {
            photoSelectVisible: false,
            updateImgContentVisable: false,
            contentIdx: '',
            photoViewCol: 6,
            updatePhotoContent: '',
            dataForm: {
                workNo: $common.getItem("workNo"),
                logDate: '',
                logNo: '',
                filePath: '',
                workLogKind: '',
                noteList: [],
                otherList: [],
                photoList: [],
                summaryList: [],
                fileList: [],
                testRecordList: [],
                inspectionList: [],
                meetList: [],
                coordinateRecordList: [],

            },
            activeIndex: 'jobSummary',
            tabs: [{ name: 'jobSummary', label: '本日（班）工作概要' },
            { name: 'signDocuments', label: '本日（班）签发文件清单' },
            { name: 'notes', label: '本日（班）备忘' },
            { name: 'atachedComments', label: '工作附图' }],
        }
    },
    components: {
        PhotoSelect
    },
    mounted() {
        this.dataForm.recNo = this.$route.query.recNo
        this.activeIndex = 'jobSummary'
        this.getDetail()
    },
    methods: {
        backPage() {
			this.$router.go(-1);
		},
        /* 照片拖拽 begin */
        cardRows(photoList) {
            const rows = [];
            if (photoList != null) {
                for (let i = 0; i < photoList.length; i += this.photoViewCol) {
                    rows.push(photoList.slice(i, i + this.photoViewCol));
                }
            }
            return rows;
        },
        updateImgContent(idxs, photoContent) {
            this.updateImgContentVisable = true;
            this.updatePhotoContent = photoContent;
            this.contentIdx = idxs;
        },
        saveImgContent() {
            this.dataForm.photoList[this.contentIdx].photoContent = this.updatePhotoContent
            this.updateImgContentVisable = false
        },
        dragStart(index) {
            this.draggedItem = { index: index };
        },
        drop(index) {
            const x = this.draggedItem.index
            const y = index
            let photoList = this.dataForm.photoList
            if (x == y) {
                return
            } else if (x < y) {
                const item = photoList.splice(x, 1)[0];
                photoList.splice(y, 0, item);
            } else {
                const item = photoList.splice(x, 1)[0];
                photoList.splice(y, 0, item);
            }
        },
        dragEnd() {
            this.draggedItem = {};
        },
        delImg(index) {
            this.dataForm.photoList.splice(index, 1);
        },
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
            // let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        handleTabClick(tab) {
            this.activeIndex = tab.name
        },
        addNote() {
            let note = {
                itemNo: '20',
                workLogRecNo: this.dataForm.recNo,
                itemContent: '',
                itemSysFlg: '1'
            }
            this.dataForm.noteList.push(note)
        },
        deleteNote(index) {
            this.dataForm.noteList.splice(index, 1)
        },
        addOther() {
            let other = {
                itemNo: '06',
                workLogRecNo: this.dataForm.recNo,
                itemContent: '',
                itemSysFlg: '1'
            }
            this.dataForm.otherList.push(other)
        },
        deleteOther(index) {
            this.dataForm.otherList.splice(index, 1)
        },
        addSummary() {
            let summary = {
                itemNo: '01',
                workLogRecNo: this.dataForm.recNo,
                itemContent: '',
                itemSysFlg: '1'
            }
            this.dataForm.summaryList.push(summary)
        },
        deleteSummary(index) {
            this.dataForm.summaryList.splice(index, 1)
        },
        addMeet() {
            let meet = {
                itemNo: '04',
                workLogRecNo: this.dataForm.recNo,
                itemContent: '',
                itemSysFlg: '1'
            }
            this.dataForm.meetList.push(meet)
        },
        deleteMeet(index) {
            this.dataForm.meetList.splice(index, 1)
        },
        selectPhoto() {
            // if (this.dataForm.logDate == undefined || this.dataForm.logDate == null || this.dataForm.logDate === '') {
            //     this.$message.error("请设置日期");
            //     return;
            // }
            this.photoSelectVisible = true
            this.$nextTick(() => {
                this.$refs.photoSelect.init(this.dataForm.logDate)
            })
        },
        margeSelectItems(items) {
            this.photoSelectVisible = false;
            // 循环处理选择的图片
            for (var selectItem of items) {
                let exist = false;
                for (var record of this.dataForm.photoList) {
                    if (selectItem.photoPathUri === record.photoFileUri) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    let photo = {
                        workLogRecNo: this.dataForm.recNo,
                        photoContent: selectItem.photoContent,
                        photoFilePath: selectItem.photoFilePath,
                        photoFileUri: selectItem.photoPathUri,
                    }
                    this.dataForm.photoList.push(photo)
                }
            }
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                    if(this.dataForm.workLogKind == null || this.dataForm.workLogKind == '') {
                        this.dataForm.workLogKind = '0'
                    }
                }
            });
        },
        getPresetInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/presetInfo/" + $common.getItem("workNo"),
                method: "get"
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.workNo = $common.getItem("workNo")
                    this.dataForm.recNo = data.body.recNo
                    this.dataForm.projectPlace = data.body.projectPlace
                    this.dataForm.logDate = this.getCurrentTime();
                }
            });
        },
        uploadScanFile(param) {
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadScanFile.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
            ).then(res => {
                res = res.data;
                if (res.resultCode === 200) {
                    this.dataForm.filePath = res.body.fileSavePath;
                    // this.dataForm.fileName = this.extractFileName(res.body.fileName);
                    this.dataForm.filePathUrl = res.body.fileUri;
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        dataFormSubmit() {
            // if (this.dataForm.logDate == undefined || this.dataForm.logDate == null || this.dataForm.logDate === '') {
            //     this.$message.error("请设置日期");
            //     return;
            // }

            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/update",
                method: "post",
                data: this.$http.adornData(this.dataForm)
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.$router.push({ name: 'workloginfo' });
                        }
                    });
                }
            });
        },
    }
}
</script>

<style scoped="scoped">
.sp-input /deep/ .el-input__inner {
    width: 350px !important;
    text-align: center;
}

.sp-input1 /deep/ .el-input__inner {
    width: 400px !important;
    text-align: center;
}

.sp-input2 /deep/ .el-input__inner {
    width: 100px !important;
    text-align: center;
}

.shift-input /deep/ .el-input__inner {
    width: 210px !important;
    margin-left: 5px !important;
    text-align: center;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.upload-demo /deep/ .el-upload-dragger {
    width: 230px;
    height: 115px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
    font-size: 40px;
    margin: -30px 0;
    line-height: 40px;
    padding-top: 45px
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
    line-height: 20px;
}

.border-form .el-row.set_border .col-set_border {
    border-right: solid #dadada 1px;
}

.border-form .el-row .col-set_border1 {
    border-right: solid #dadada 1px;
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    margin-right: -0.5px;
}

.border-form .el-row .col-set_border2 {
    border-right: solid #dadada 1px;
    border-bottom: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

.item::v-deep .el-form-item__label {
    color: #00428e !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right:solid #dadada 1px;
}

.row-form-item {
    margin-top: 10px;
}

.row-form-item .el-form-item {
    margin-right: 0;
}

.shift-item {
    color: #00428e !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    font-size: 15px;
    height: 40px;
    width: 220px;
    padding-top: 12px;
    padding-left: 100px;
}

::v-deep .el-tabs__item.is-active {
    color: #1d1d86;
    background-color: #E1F1FF;
}

::v-deep .el-tabs__item:hover {
    color: #121213;
    background-color: #eceeed;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
    width: 338px;
    text-align: center;
    border: #cfcfd4 solid 1px;
    /* background-color: white; */
}

::v-deep .el-tabs__item {
    background-color: #e5ebf5;
    font-family: "boschsans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tabItem::v-deep .el-form-item__label {
    text-align: left;
    padding-left: 20px;
    color: #00428e !important;
    margin: 5px 0px 10px 0px !important;
    ;
    font-weight: bold !important;
    ;
    width: 1500px !important;
    background-color: #E1F1FF;
}

.el-form--inline .el-form-item {
    margin-right: 0px;
}

::v-deep .note_input .el-input__inner {
    border-radius: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    width: 1570px;
}

::v-deep .note_input1 .el-textarea__inner {
    padding-right: 50px !important;
    border: 0px;
    resize: none;
    /* border-bottom: 1px solid #dadada; */
}

::v-deep .test_input .el-input__inner {
    border-radius: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    width: 1570px;
}

.prob_btn {
    margin-left: -60px;
    margin-top: 10px;
    position: absolute;
}

.image {
    width: 90%;
    height: 200px;
    margin-left: 12px;
    display: block;
}

.t-column-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding-bottom: 10px;
    height: 50px;
}

.button {
    padding: 0;
    float: right;
}
</style>
